import React from 'react';
import ImageGallery  from 'react-image-gallery';
import { Divider } from 'antd';
import { Footer } from '../common/footer/footer';
import { useTranslation } from 'react-i18next';
import { GetHouseById } from '../../service/propertyBuilding';
import { BULLETIN } from '../../common/constants';
import { useGallery } from '../../service/common';
import { Loader } from '../common/loader/loader';

export const PropertyBuildingView: React.FC = () => {
    const { t } = useTranslation();

    const { housesData, housesError } = GetHouseById();

    const { isLoading, gallery } = useGallery();

    if (housesError) {
        return (
            <h1>SORRY</h1>
        )
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <section id="gallery">
                <ImageGallery
                    showPlayButton={false}
                    items={gallery}
                    showFullscreenButton={true}
                    showBullets={true}
                />
            </section>
            {housesData && (
                <section id="house">
                    <p className="title">{housesData.title}</p>
                    <p className="description">{housesData.description}</p>
                    <Divider />
                    <p className="sub-title">{t("house.info")}</p>
                    <div className="facilities">
                        <p>{BULLETIN} {t("house.price")} <span className="bold">{housesData.price} €</span></p>
                        <p>{BULLETIN} {t("house.year_of_build")} <span className="bold">{housesData.buildYear}.</span></p>
                        <p>{BULLETIN} {t("house.year_of_renovation")} <span className="bold">{housesData.renovationYear}.</span></p>
                        <p>{BULLETIN} {t("house.house_square_metres")} <span className="bold">{housesData.houseSquareMetres} m2</span></p>
                        <p>{BULLETIN} {t("house.square_metres")} <span className="bold">{housesData.squareMetres} m2</span></p>
                        <p>{BULLETIN} {t("house.bathrooms")} <span className="bold">{housesData.bathrooms}</span></p>
                        <p>{BULLETIN} {t("house.rooms")} <span className="bold">{housesData.rooms}</span></p>
                        <p>{BULLETIN} {t("house.pool")} <span className="bold">{housesData.outdoorPool ? t("yes") : t("no")}</span></p>
                    </div>
                    <Divider />
                    <p className="sub-title">{t("house.additional_content")}</p>
                    <div className="additional">
                        {housesData.additional.map((add: string) => (
                            <p>{BULLETIN} {add}</p>
                        ))}
                    </div>
                    <Divider />
                    <p className="sub-title">{t("house.contact")}</p>
                    <div className="contact">
                        <p>{BULLETIN} {t("house.id")} <span className="bold">{housesData.id}</span></p>
                        <p>{BULLETIN} {t("house.agent")} <span className="bold">{housesData.agent}</span></p>
                        <p>{BULLETIN} {t("house.mobile")} <span className="bold">{housesData.mobile}</span></p>
                        <p>{BULLETIN} E-Mail: <span className="bold">{housesData.email}</span></p>
                    </div>
                </section>
            )}
            <Footer />
        </>
    );
};
