import React, { useEffect, useState } from 'react';
import { PlotDocument } from '../../common/typings';
import { ALL_LOCATIONS, ISTRIA, KVARNER } from '../../common/constants';
import { PlotCard } from '../common/plotCard/plotCard';
import { GetPlots, GetPlotsByDestination } from '../../service/plots';
import { Footer } from '../common/footer/footer';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export const PlotsView: React.FC = () => {
	const { t } = useTranslation();
	const [destination, setDestination] = useState<string>('');
	const [plotsData, setPlotsData] = useState<PlotDocument[] | undefined>(undefined);

	useEffect(() => {
		function getPlots() {
			GetPlots().then(payload => setPlotsData(payload));
		}

		function getPlotsByDestination() {
			GetPlotsByDestination(destination).then(payload => setPlotsData(payload));
		}

		destination === ALL_LOCATIONS ? getPlots() : getPlotsByDestination();
	}, [destination]);

	return (
		<>
			<section id="buy-house">
				<div className="search">
					<div>
						<label>{t('destinations.choose_location')}</label>
						<Select className="select" defaultValue={destination} onChange={value => setDestination(value)}>
							<Option value={ALL_LOCATIONS}>{t('destinations.all_locations')}</Option>
							<Option value={ISTRIA}>{t("destinations.ISTRIA")}</Option>
							<Option value={KVARNER}>{t("destinations.KVARNER")}</Option>
						</Select>
					</div>
				</div>
				{plotsData && plotsData.length !== 0 && (
					<>
						<h1>{resolveDestination(destination)}</h1>
						<div className="grid-3">
							{plotsData.map((plot: PlotDocument) => (
								<PlotCard
									key={Math.random()}
									id={plot.id}
									shortDescription={plot.shortDescription}
									pricePerSquare={plot.pricePerSquare}
									location={plot.location}
									cover={plot.cover}
									loading={false}
									price={plot.price}
									squareMetres={plot.squareMetres}
									isBought={plot.isBought || false}
									isReserved={plot.isReserved || false}
									isNewListing={plot.isNewListing || false}
								/>
							))}
						</div>
					</>
				)}
			</section>
			<Footer />
		</>
	);

	function resolveDestination(destination: string) {
		if (destination === ALL_LOCATIONS)
			return t("destinations.all_locations")
		else if (destination === ISTRIA)
			return t("destinations.ISTRIA")
		else
			return t("destinations.KVARNER")
	}
};
