import React from 'react';
import { CardBannerProps } from '../../../common/typings';
import { useTranslation } from 'react-i18next';

export const CardBanners: React.FC<CardBannerProps> = (props: CardBannerProps) => {
	const { t } = useTranslation();
	const { isBought, isReserved, isNewListing } = props;

	if (isBought) {
		return (
			<div className="banner color-red">
				<p>{t('card.bought')}</p>
			</div>
		)
	} else if (isReserved) {
		return (
			<div className="banner color-red">
				<p>{t('card.reserved')}</p>
			</div>
		)
	} else if (isNewListing) {
		return (
			<div className="banner color-blue">
				<p>{t('card.newListing')}</p>
			</div>
		)
	} else {
		return null;
	}
};
