import { CondoDocument, CondoResponse, CondosResponse } from '../common/typings';
import { getLanguage, UseQuery } from '../common/utils';
import { CONDOS_COLLECTION, DESTINATION, EQUALS, IS_FEATURED, PURCHASE_COLLECTION } from '../common/constants';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { firestore } from './firebase';

export function GetFeaturedCondos(): CondosResponse {
	const [data, loading, error] = useCollectionDataOnce(firestore.collection(getLanguage() + PURCHASE_COLLECTION + CONDOS_COLLECTION).where(IS_FEATURED, EQUALS, true));

	return { condosData: data, condosLoading: loading, condosError: error } as CondosResponse;
}

export function GetCondoById(): CondoResponse {
	const id = UseQuery().get('id') || '';

	const [data, loading, error] = useDocumentDataOnce(firestore.collection(getLanguage() + PURCHASE_COLLECTION + CONDOS_COLLECTION).doc(id));

	return { condoData: data, condoLoading: loading, condoError: error } as CondoResponse;
}

export const GetCondos = async () => {
	let data: CondoDocument[] = [];

	let collectionRef = firestore.collection(getLanguage() + PURCHASE_COLLECTION + CONDOS_COLLECTION);
	let allCondos = await collectionRef.get();
	for (const doc of allCondos.docs) {
		data.push(doc.data() as CondoDocument);
	}

	data.sort(function (a: CondoDocument, b: CondoDocument) {
		return a.index - b.index;
	});

	return data;
};

export const GetCondosByDestination = async (destination: string) => {
	let data: CondoDocument[] = [];

	let collectionRef = firestore.collection(getLanguage() + PURCHASE_COLLECTION + CONDOS_COLLECTION).where(DESTINATION, EQUALS, destination);
	let allCondos = await collectionRef.get();
	for (const doc of allCondos.docs) {
		data.push(doc.data() as CondoDocument);
	}

	data.sort(function (a: CondoDocument, b: CondoDocument) {
		return a.index - b.index;
	});

	return data;
};
