import { PlotDocument, PlotResponse, PlotsResponse } from '../common/typings';
import { getLanguage, UseQuery } from '../common/utils';
import { DESTINATION, EQUALS, IS_FEATURED, PLOTS_COLLECTION, PURCHASE_COLLECTION } from '../common/constants';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { firestore } from './firebase';

export function GetFeaturedPlots(): PlotsResponse {
	const [data, loading, error] = useCollectionDataOnce(firestore.collection(getLanguage() + PURCHASE_COLLECTION + PLOTS_COLLECTION).where(IS_FEATURED, EQUALS, true));

	return { plotData: data, plotLoading: loading, plotError: error } as PlotsResponse;
}

export function GetPlotById(): PlotResponse {
	const id = UseQuery().get('id') || '';

	const [data, loading, error] = useDocumentDataOnce(firestore.collection(getLanguage() + PURCHASE_COLLECTION + PLOTS_COLLECTION).doc(id));

	return { plotData: data, plotLoading: loading, plotError: error } as PlotResponse;
}

export const GetPlots = async () => {
	let data: PlotDocument[] = [];

	let collectionRef = firestore.collection(getLanguage() + PURCHASE_COLLECTION + PLOTS_COLLECTION);
	let allHouses = await collectionRef.get();
	for (const doc of allHouses.docs) {
		data.push(doc.data() as PlotDocument);
	}

	data.sort(function (a: PlotDocument, b: PlotDocument) {
		return a.index - b.index;
	});

	return data;
};

export const GetPlotsByDestination = async (destination: string) => {
	let data: PlotDocument[] = [];

	let collectionRef = firestore.collection(getLanguage() + PURCHASE_COLLECTION + PLOTS_COLLECTION).where(DESTINATION, EQUALS, destination);
	let allHouses = await collectionRef.get();
	for (const doc of allHouses.docs) {
		data.push(doc.data() as PlotDocument);
	}

	data.sort(function (a: PlotDocument, b: PlotDocument) {
		return a.index - b.index;
	});

	return data;
};
