import React, { useState } from 'react';
import { Button, Input, message } from 'antd';
import emailjs from 'emailjs-com';
import { Footer } from '../common/footer/footer';
import { useTranslation } from 'react-i18next';
import { SERVICE_ID, CONTACT_TEMPLATE_ID, USER_ID } from '../../common/constants';

const { TextArea } = Input;

export const Contact: React.FC = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    return (
        <section id="contact">
            <p className="text">{t("contact.title")}<br/><br/><span className="italic">{t("about.yours")}</span></p>
            <div className="form">
                <form onSubmit={sendEmail}>
                    <label>{t("contact.full_name")}</label>
                    <Input className="input" size="large" name="from_name" />
                    <label>e-mail</label>
                    <Input className="input" type="email" size="large" name="reply_to" />
                    <label className="msg-label">{t("contact.message")}</label>
                    <TextArea className="text-area" rows={5} name="message" />
                    <Button loading={loading} className="btn" type="primary" htmlType="submit">{t("contact.send")}</Button>
                </form>
            </div>
            <div className="contact-info">
                <iframe
                    title="contact-location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.32318459381!2d13.932086515765542!3d44.8557150818581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47632d142f19ceb9%3A0x42aa2ee7b60cca75!2sLux%20Adria%2C%20za%20usluge%20turisti%C4%8Dke%20agencije%20i%20prodaju%20nekretnina!5e0!3m2!1shr!2shr!4v1610275237767!5m2!1shr!2shr"
                    height="350" frameBorder="0" aria-hidden="false"
                />
            </div>
            <Footer />
        </section>
    );

    function sendEmail(e: any) {
        e.preventDefault();
        setLoading(true);

        emailjs.sendForm(SERVICE_ID, CONTACT_TEMPLATE_ID, e.target, USER_ID)
            .then(() => {
                setLoading(false);
                message.success(t("contact.success"));
            }, () => {
                setLoading(false);
                message.error(t("contact.error"));
            });
    }
};
