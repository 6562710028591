import React from 'react';
import { CondoCardProps } from '../../../common/typings';
import { Card, Button } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import houseSquare from '../../../images/house_square_footage.svg';
import bathroom from '../../../images/bathroom.svg';
import bedroom from '../../../images/bedroom.svg';
import stairs from '../../../images/stairs.svg';
import { CardBanners } from '../cardBanners/card-banners';

export const CondoCard: React.FC<CondoCardProps> = (props: CondoCardProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id, cover, loading, floors, rooms, bathrooms, condoSquareMetres, price, isBought, isReserved, isNewListing } = props;

	const routeChange = () =>{
		let path = `/condo?id=` + id;

		history.push(path);
	}

	return (
		<div className="featured-card">
			<Card
				className="card"
				hoverable
				loading={loading}
				cover={
					<a href="javascript:void(0)" onClick={routeChange}><img alt="house cover" src={cover} /></a>
				}
			>
				<Meta
					title={id}
				/>
				<Button
					onClick={routeChange}
					className="btn"
					type="primary"
					block
				>
					{t("card.action")}
				</Button>
				<div className="info">
					<div className="rows">
						<div className="featured-detail square">
							<img width={25} src={houseSquare} alt="houseSquare" />
							<p className="category right"><span>{condoSquareMetres}</span> m2</p>
						</div>
						<p className="category center">|</p>
						<div className="featured-detail right">
							<img width={25} src={bedroom} alt="bedrooms" />
							<p className="category right"><span>{rooms}</span></p>
						</div>
					</div>
					<div className="rows">
						<div className="featured-detail">
							<img width={25} src={stairs} alt="square" />
							<p className="category"><span>{floors}</span></p>
						</div>
						<p className="category center">|</p>
						<div className="featured-detail right">
							<img width={25} src={bathroom} alt="bathrooms" />
							<p className="category right"><span>{bathrooms}</span></p>
						</div>
					</div>
				</div>
				<p className="text-center"><span>{price}</span> €</p>
				<CardBanners
					isBought={isBought}
					isReserved={isReserved}
					isNewListing={isNewListing}
				/>
			</Card>
		</div>
	);
};
