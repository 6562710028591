import React, { useState } from 'react';
import ImageGallery  from 'react-image-gallery';
import { Tabs, Divider, Input, DatePicker, Button, Popover, message, Table } from 'antd';
import { Footer } from '../common/footer/footer';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { BOOKING_TEMPLATE_ID, BULLETIN, SERVICE_ID, USER_ID } from '../../common/constants';
import { GetRentalById } from '../../service/rentals';
import { useGallery } from '../../service/common';
import emailjs from 'emailjs-com';
import moment from 'moment';
import { Loader } from '../common/loader/loader';

export const RentalView: React.FC = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>('');
    const [toDate, setToDate] = useState<string>('');

    const { TabPane } = Tabs;
    const { RangePicker } = DatePicker;
    const { TextArea } = Input;

    const { rentalData, rentalLoading } = GetRentalById();

    const { isLoading, gallery } = useGallery();

    const dataSource = generateDataSource();

    const columns = [
        {
            title: 'Datum',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: t("rental.price"),
            dataIndex: 'price',
            key: 'price',
        },
    ];

    const availability: string[][] = generateAvailability(rentalData?.availability)

    function generateDataSource() {
        if (rentalData?.priceList) {
            const dataSource: { key: number; date: string; price: string; }[] = [];

            rentalData.priceDates.map((date, index) => {
                dataSource.push({ key: Math.random(), date, price: rentalData.priceList[index] })
            })

            return dataSource;
        }
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <section id="gallery">
                <ImageGallery
                    showPlayButton={false}
                    items={gallery}
                    showFullscreenButton={true}
                    showBullets={true}
                />
            </section>
            <Tabs defaultActiveKey="1">
                <TabPane tab={t("rental.content")} key="1">
                    {rentalData && (
                        <p className="description">{rentalData.description}</p>
                    )}
                    <Divider />
                    <h1>{t("rental.general_info")}</h1>
                    <div className="content-grid-2 facilities">
                        {rentalData && (
                            rentalData.categories.addedValue.map((value: string) => (
                                <p key={Math.random()}>{BULLETIN} {value}</p>
                            ))
                        )}
                    </div>
                    <Divider />
                    <h1>{t("rental.content")}</h1>
                    <div className="content-grid-2 facilities">
                        {rentalData && (
                            rentalData.categories.facilities.map((value: string) => (
                                <p key={Math.random()}>{BULLETIN} {value}</p>
                            ))
                        )}
                    </div>
                    <Divider />
                    <h1>{t("rental.rooms")}</h1>
                    <div className="content-grid-2 facilities">
                        {rentalData && (
                            rentalData.categories.rooms.map((value: string) => (
                                <p key={Math.random()}>{BULLETIN} {value}</p>
                            ))
                        )}
                    </div>
                    <Divider />
                    <h1>{t("rental.kitchen")}</h1>
                    <div className="content-grid-2 facilities">
                        {rentalData && (
                            rentalData.categories.kitchen.map((value: string) => (
                                <p key={Math.random()}>{BULLETIN} {value}</p>
                            ))
                        )}
                    </div>
                    <Divider />
                    <h1>{t("rental.outside")}</h1>
                    <div className="content-grid-2 facilities">
                        {rentalData && (
                            rentalData.categories.outside.map((value: string) => (
                                <p key={Math.random()}>{BULLETIN} {value}</p>
                            ))
                        )}
                    </div>
                    <Divider />
                    <h1>{t("rental.nearby")}</h1>
                    <div className="content-grid-2 facilities">
                        {rentalData && (
                            rentalData.categories.nearby.map((value: string) => (
                                <p key={Math.random()}>{BULLETIN} {value}</p>
                            ))
                        )}
                    </div>
                </TabPane>
                {!rentalLoading && !rentalData?.isNovasolBooking && (
                    renderBookVacationTab()
                )}
                <TabPane tab={t("rental.location")} key="3">
                    {rentalData && (
                        <iframe title="rental-location" src={rentalData.location} frameBorder="0" width={"100%"} height={"500px"} aria-hidden="false" />
                    )}
                </TabPane>
            </Tabs>
            {rentalData?.isNovasolBooking && (
                <div className="novasol-booking">
                    <Button
                        size={'large'}
                        className="book-btn"
                        href={rentalData?.novasolLink}
                        target="_blank"
                        type="primary"
                    >
                        {t("rental.book_with_novasol")}
                        <Popover content={t("rental.icon")} trigger="hover">
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </Button>
                </div>
            )}
            <Footer />
        </>
    );

    function renderBookVacationTab() {
        return(
            <TabPane tab={t("rental.book_vacation")} key="2">
                <div className="book">
                    <div className="text">
                        <h1>{t("rental.book_yours_vacation")}</h1>
                        <div>
                            <hr/>
                            <p>{t("rental.make_request")}</p>
                            <hr/>
                        </div>
                    </div>
                    <form onSubmit={sendEmail} className="form">
                        <div className="grid-2">
                            <Input value={rentalData?.id} hidden={true} name="id" />
                            <div>
                                <label>{t("contact.full_name")}</label>
                                <Input className="input" size="large" name="from_name" />
                            </div>
                            <div>
                                <label>E-Mail</label>
                                <Input className="input" size="large" name="reply_to" />
                            </div>
                        </div>
                        <div>
                            <label>{t("rental.check_in_out")}</label>
                            <RangePicker
                                disabledDate={date => disabledDates(date)}
                                format={'DD-MM-YYYY'}
                                size="large"
                                onChange={(momentDate, dateString) => formatDates(dateString)}
                            />
                            <Input value={fromDate} hidden={true} name="from_date" />
                            <Input value={toDate} hidden={true} name="to_date" />
                        </div>
                        <div className="grid-4">
                            <div>
                                <label>{t("rental.grown_ups")}</label>
                                <Input type={'number'} min={1} className="input" size="large" name="grown_ups" />
                            </div>
                            <div>
                                <label>{t("rental.kids")}</label>
                                <Input type={'number'} min={0} className="input" size="large" name="kids" />
                            </div>
                            <div>
                                <label>{t("rental.infants")}</label>
                                <Input type={'number'} min={0} className="input" size="large" name="infants" />
                            </div>
                            <div>
                                <label>{t("rental.pets")}</label>
                                <Input type={'number'} min={0} className="input" size="large" name="pets" />
                            </div>
                        </div>
                        <div>
                            <label>{t("contact.message")}</label>
                            <TextArea rows={5} name="message" />
                        </div>
                        <Button loading={loading} htmlType={'submit'} className="send-btn" size="large" type="primary">{t("contact.send")}</Button>
                    </form>
                </div>
                {rentalData?.priceList && (
                    <div className="priceList">
                        <h1>{t("rental.price_list")}</h1>
                        <Table
                            pagination={false}
                            className="table"
                            dataSource={dataSource}
                            columns={columns}
                            footer={renderTableFooter}
                        />
                    </div>
                )}
                <section className="cancellation-policy">
                    <h1>{t("rental.policy")}</h1>
                    <div>
                        <div>
                            <h2>{t("rental.cancellation_policy")}</h2>
                            <Divider />
                            <p>{t("rental.100")}</p>
                            <p>{t("rental.50")}</p>
                        </div>
                    </div>
                </section>
            </TabPane>
        )
    }

    function renderTableFooter() {
        if (rentalData?.additionalPricing) {
            return (
                <div className="additionalPricing">
                    {rentalData.additionalPricing.map((desc: string) => (
                        <p>{desc}</p>
                    ))}
                </div>
            )
        }
    }

    function formatDates(selectedDates: [string, string]) {
        setFromDate(selectedDates[0]);
        setToDate(selectedDates[1]);
    }

    function sendEmail(e: any) {
        e.preventDefault();
        setLoading(true);

        emailjs.sendForm(SERVICE_ID, BOOKING_TEMPLATE_ID, e.target, USER_ID)
            .then(() => {
                setLoading(false);
                message.success(t("rental.booking_msg"));
            }, () => {
                setLoading(false);
                message.error(t("contact.error"));
            });
    }

    function disabledDates(current: moment.Moment) {
        for (let _i = 0; _i < availability.length; _i++) {
            if (!moment(current).isBetween(availability[_i][0], availability[_i][1]))
                return true
        }

        return false;
    }

    function generateAvailability(availability?: string[]): string[][] {
        const array: string[][] = [];

        if (availability === undefined) return array;

        for (let _i = 0; _i < availability.length; _i++) {
            let dates: string[] = availability[_i].split("°")
            array.push(dates)
        }

        return array;
    }
};
