import React from 'react';
import { RentalCardProps } from '../../../common/typings';
import { Card, Button, Rate } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import houseSquare from '../../../images/house_square_footage.svg';
import bathroom from '../../../images/bathroom.svg';
import bedroom from '../../../images/bedroom.svg';
import people from '../../../images/people.svg';

export const RentalCard: React.FC<RentalCardProps> = (props: RentalCardProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id, rate, cover, loading, featuredDetails, description } = props;

	const routeChange = () =>{
		let path = `/rental?id=` + id;

		history.push(path);
	}

	return (
		<div className="featured-card">
			<Card
				className="card"
				hoverable
				loading={loading}
				cover={
					<a href="javascript:void(0)" onClick={routeChange}><img alt="villa cover" src={cover} /></a>
				}
			>
				<Meta
					title={id}
					description={description}
				/>
				<Rate disabled defaultValue={rate} />
				<Button
					onClick={routeChange}
					className="btn"
					type="primary"
					block
				>
					{t("card.action_rental")}
				</Button>
				<div className="info">
					<div className="rows">
						<div className="featured-detail square">
							<img width={25} src={houseSquare} alt="houseSquare" />
							<p className="category"><span>{featuredDetails.square}</span> m2</p>
						</div>
						<p className="category center">|</p>
						<div className="featured-detail text right">
							<p className="min_nights">{t("card.min_nights")}</p>
							<p className="category"><span>{featuredDetails.minNights}</span></p>
						</div>
					</div>
					<div className="rows">
						<div className="featured-detail">
							<img width={25} src={bedroom} alt="bedrooms" />
							<p className="category"><span>{featuredDetails.bedrooms}</span></p>
						</div>
						<p className="category center">|</p>
						<div className="featured-detail  right">
							<img width={25} src={bathroom} alt="bathrooms" />
							<p className="category"><span>{featuredDetails.bathrooms}</span></p>
						</div>
					</div>
				</div>
				<div className="featured-detail-flex">
					<img width={25} src={people} alt="max-guests" />
					<p className="text-center"><span>{featuredDetails.guests}</span></p>
				</div>
			</Card>
		</div>
	);
};
