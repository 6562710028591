import { RentalDocument, RentalResponse, RentalsResponse } from '../common/typings';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { getLanguage, UseQuery } from '../common/utils';
import { DESTINATION, EQUALS, IS_FEATURED, IS_READY, RENTAL_COLLECTION } from '../common/constants';
import { firestore } from './firebase';

export function GetFeaturedRentals(): RentalsResponse {
	const [data, loading, error] = useCollectionDataOnce(firestore.collection(getLanguage() + RENTAL_COLLECTION).where(IS_FEATURED, EQUALS, true).where(IS_READY, EQUALS, true));

	return { rentalData: data, rentalLoading: loading, rentalError: error } as RentalsResponse;
}

export function GetRentalById(): RentalResponse {
	const id = UseQuery().get('id') || '';

	const [data, loading, error] = useDocumentDataOnce(firestore.collection(getLanguage() + RENTAL_COLLECTION).doc(id));

	return { rentalData: data, rentalLoading: loading, rentalError: error } as RentalResponse;
}

export function GetRentalsByDestination(destination: string): RentalsResponse {
	const [data, loading, error] = useCollectionDataOnce(firestore.collection(getLanguage() + RENTAL_COLLECTION).where(DESTINATION, EQUALS, destination).where(IS_READY, EQUALS, true));

	// @ts-ignore
	data?.sort(function (a: RentalDocument, b: RentalDocument) {
		return a.index - b.index;
	});

	return { rentalData: data, rentalLoading: loading, rentalError: error } as RentalsResponse;
}
