import { PropertyBuildingDocument, PropertyBuildingResponse, PropertyBuildingsResponse } from '../common/typings';
import { getLanguage, UseQuery } from '../common/utils';
import { DESTINATION, EQUALS, HOUSES_COLLECTION, IS_FEATURED, IS_READY, PURCHASE_COLLECTION } from '../common/constants';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { firestore } from './firebase';

export function GetFeaturedHouses(): PropertyBuildingsResponse {
	const [data, loading, error] = useCollectionDataOnce(firestore.collection(getLanguage() + PURCHASE_COLLECTION + HOUSES_COLLECTION).where(IS_FEATURED, EQUALS, true).where(IS_READY, EQUALS, true));

	return { housesData: data, housesLoading: loading, housesError: error } as PropertyBuildingsResponse;
}

export function GetHouseById(): PropertyBuildingResponse {
	const id = UseQuery().get('id') || '';

	const [data, loading, error] = useDocumentDataOnce(firestore.collection(getLanguage() + PURCHASE_COLLECTION + HOUSES_COLLECTION).doc(id));

	return { housesData: data, housesLoading: loading, housesError: error } as PropertyBuildingResponse;
}

export const GetHouses = async () => {
	let data: PropertyBuildingDocument[] = [];

	let collectionRef = firestore.collection(getLanguage() + PURCHASE_COLLECTION + HOUSES_COLLECTION).where(IS_READY, EQUALS, true);
	let allHouses = await collectionRef.get();
	for (const doc of allHouses.docs) {
		data.push(doc.data() as PropertyBuildingDocument);
	}

	data.sort(function (a: PropertyBuildingDocument, b: PropertyBuildingDocument) {
		return a.index - b.index;
	});

	return data;
};

export const GetHousesByDestination = async (destination: string) => {
	let data: PropertyBuildingDocument[] = [];

	let collectionRef = firestore.collection(getLanguage() + PURCHASE_COLLECTION + HOUSES_COLLECTION).where(DESTINATION, EQUALS, destination).where(IS_READY, EQUALS, true);
	let allHouses = await collectionRef.get();

	for (const doc of allHouses.docs) {
		data.push(doc.data() as PropertyBuildingDocument);
	}

	data.sort(function (a: PropertyBuildingDocument, b: PropertyBuildingDocument) {
		return a.index - b.index;
	});

	return data;
};
