import React, { useEffect } from 'react';
import { Carousel, notification } from 'antd';
import { Footer } from '../common/footer/footer';
import { RentalCard } from '../common/rentalCard/rentalCard';
import { PropertyBuildingDocument, PlotDocument, RentalDocument, CondoDocument } from '../../common/typings';
import { useTranslation } from 'react-i18next';
import { RealEstateCard } from '../common/realEstateCard/realEstateCard';
import { PlotCard } from '../common/plotCard/plotCard';
import { GetFeaturedHouses } from '../../service/propertyBuilding';
import { GetFeaturedRentals } from '../../service/rentals';
import { GetFeaturedPlots } from '../../service/plots';
import { GetFeaturedCondos } from '../../service/condos';
import { CondoCard } from '../common/condoCard/condo-card';

export const LandingPage: React.FC = () => {
    const { t } = useTranslation();
    const { rentalData, rentalLoading, rentalError } = GetFeaturedRentals();
    const { housesData, housesLoading, housesError } = GetFeaturedHouses();
    const { plotData, plotLoading } = GetFeaturedPlots();
    const { condosData, condosLoading } = GetFeaturedCondos();

    useEffect(() => {
        const displayRentalError = () => {
            notification["error"]({
                message: rentalError?.message
            });
        }

        const displayPurchaseError = () => {
            notification["error"]({
                message: housesError?.message
            });
        }

        if(rentalError) {
            displayRentalError();
        }

        if(housesError) {
            displayPurchaseError();
        }
    }, [rentalError, housesError])

    return (
        <>
            <section id="home">
                <Carousel autoplay>
                    <div className="cover-1">
                        <p className="cover-text">{t("home.cover1-title")}</p>
                    </div>
                    <div className="cover-2">
                        <p className="cover-text">{t("home.cover2-title")}</p>
                    </div>
                    <div className="cover-3">
                        <p className="cover-text">{t("home.cover3-title")}</p>
                    </div>
                </Carousel>
            </section>
            <section className="featured">
                {rentalData && rentalData.length !== 0 && (
                    <>
                        <h1>{t("home.book_vacation")}</h1>
                        <div className="grid-3">
                            {rentalData.map((rental: RentalDocument) => (
                                <RentalCard
                                    key={Math.random()}
                                    featuredDetails={rental.featuredDetails}
                                    id={rental.id}
                                    location={rental.location}
                                    cover={rental.cover}
                                    description={rental.shortDescription || ""}
                                    loading={rentalLoading}
                                    rate={rental.rate}
                                />
                            ))}
                        </div>
                    </>
                )}
            </section>
            <section className="featured">
                {housesData && housesData.length !== 0 && (
                    <>
                        <h1>{t("home.buy_house")}</h1>
                        <div className="grid-3">
                            {housesData.map((house: PropertyBuildingDocument) => (
                                <RealEstateCard
                                    key={Math.random()}
                                    id={house.id}
                                    location={house.location}
                                    cover={house.cover}
                                    loading={housesLoading}
                                    rooms={house.rooms}
                                    bathrooms={house.bathrooms}
                                    price={house.price}
                                    squareMetres={house.squareMetres}
                                    houseSquareMetres={house.houseSquareMetres}
                                    isBought={house.isBought || false}
                                    isReserved={house.isReserved || false}
                                    isNewListing={house.isNewListing || false}
                                />
                            ))}
                        </div>
                    </>
                )}
            </section>
            <section className="featured">
                {condosData && condosData.length !== 0 && (
                    <>
                        <h1>{t("home.buy_condo")}</h1>
                        <div className="grid-3">
                            {condosData.map((condo: CondoDocument) => (
                                <CondoCard
                                    key={Math.random()}
                                    id={condo.id}
                                    cover={condo.cover}
                                    loading={condosLoading}
                                    condoSquareMetres={condo.condoSquareMetres}
                                    floors={condo.floors}
                                    rooms={condo.rooms}
                                    bathrooms={condo.bathrooms}
                                    price={condo.price}
                                    isBought={condo.isBought || false}
                                    isReserved={condo.isReserved || false}
                                    isNewListing={condo.isNewListing || false}
                                />
                            ))}
                        </div>
                    </>
                )}
            </section>
            <section className="featured">
                {plotData && plotData.length !== 0 && (
                    <>
                        <h1>{t("home.buy_plot")}</h1>
                        <div className="grid-3">
                            {plotData.map((plot: PlotDocument) => (
                                <PlotCard
                                    key={Math.random()}
                                    id={plot.id}
                                    shortDescription={plot.shortDescription}
                                    pricePerSquare={plot.pricePerSquare}
                                    location={plot.location}
                                    cover={plot.cover}
                                    loading={plotLoading}
                                    price={plot.price}
                                    squareMetres={plot.squareMetres}
                                    isBought={plot.isBought || false}
                                    isReserved={plot.isReserved || false}
                                    isNewListing={plot.isNewListing || false}
                                />
                            ))}
                        </div>
                    </>
                )}
            </section>
            <Footer />
        </>
    );
};
