import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Navbar } from './components/common/navbar/navbar';
import { LandingPage } from './components/landingPage/landingPage';
import { About } from './components/about';
import { RentalsView } from './components/rentalsView/rentals-view';
import { RentalView } from './components/rentalView/rentalView';
import { PropertyBuildingView } from './components/propertyBuildingView/property-building-view';
import { CondoView } from './components/condoView/condo-view';
import { CondosView } from './components/condosView/condos-view';
import { PlotView } from './components/plotView/plotView';
import { RentalLocations } from './components/rentalLocations/rental-locations';
import { RealEstateTypes } from './components/realEstateTypes/real-estate-types';
import { Contact } from './components/contact/contact';
import { PropertyBuildingsView } from './components/propertyBuildingsView/property-buildings-view';
import { PlotsView } from './components/plotsView/plots-view';
import ScrollToTop from './components/common/scrollToTop/ScrollToTop';

import './style/main.css';

import reportWebVitals from './reportWebVitals';

// Multilingual
import './i18n';

// AntD
import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';
import hr_HR from 'antd/lib/locale/hr_HR';

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div>Loading...</div>}>
			<ConfigProvider locale={hr_HR}>
				<Router>
					<ScrollToTop />
					<Navbar />
					<Switch>
						<Route exact path="/">
							<LandingPage />
						</Route>
						<Route exact path="/who-we-are">
							<About />
						</Route>
						<Route exact path="/rentals-view">
							<RentalsView />
						</Route>
						<Route exact path="/rental-locations">
							<RentalLocations />
						</Route>
						<Route exact path="/real-estate-types">
							<RealEstateTypes />
						</Route>
						<Route exact path="/buy-house">
							<PropertyBuildingsView />
						</Route>
						<Route exact path="/buy-plot">
							<PlotsView />
						</Route>
						<Route exact path="/condos-view">
							<CondosView />
						</Route>
						<Route exact path="/rental">
							<RentalView />
						</Route>
						<Route exact path="/house">
							<PropertyBuildingView />
						</Route>
						<Route exact path="/condo">
							<CondoView />
						</Route>
						<Route exact path="/plot">
							<PlotView />
						</Route>
						<Route exact path="/contact">
							<Contact />
						</Route>
					</Switch>
				</Router>
			</ConfigProvider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
