import React from 'react';
import mrotimSig from '../../images/mrotimsig.png';
import jdragicSig from '../../images/jdragicsig.png';

import { Footer } from '../common/footer/footer';
import { useTranslation } from 'react-i18next';

export const About: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<section id="about">
				<div className="paper">
					<h1>{t("about.title")}</h1>
					<p>&nbsp;&nbsp;&nbsp;&nbsp;{t("about.first")}</p>
					<p>&nbsp;&nbsp;&nbsp;&nbsp;{t("about.second")}</p>
					<p>&nbsp;&nbsp;&nbsp;&nbsp;{t("about.third")}</p>
					<br/>
					<h3>{t("about.yours")}</h3>
					<div className="signatures">
						<div>
							<p>Jozo Dragić</p>
							<img className="sig" src={jdragicSig} alt="sig" />
						</div>
						<div>
							<p>Matej Rotim</p>
							<img className="sig" src={mrotimSig} alt="sig" />
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};
