import React from 'react';
import ImageGallery  from 'react-image-gallery';
import { Divider } from 'antd';
import { Footer } from '../common/footer/footer';
import { useTranslation } from 'react-i18next';
import { BULLETIN } from '../../common/constants';
import { useGallery } from '../../service/common';
import { Loader } from '../common/loader/loader';
import { GetCondoById } from '../../service/condos';

export const CondoView: React.FC = () => {
    const { t } = useTranslation();

    const { condoData, condoError } = GetCondoById();

    const { isLoading, gallery } = useGallery();

    if (condoError) {
        return (
            <h1>SORRY</h1>
        )
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <section id="gallery">
                <ImageGallery
                    showPlayButton={false}
                    items={gallery}
                    showFullscreenButton={true}
                    showBullets={true}
                />
            </section>
            {condoData && (
                <section id="house">
                    <p className="title">{condoData.title}</p>
                    <p className="description">{condoData.description}</p>
                    <Divider />
                    <p className="sub-title">{t("house.info")}</p>
                    <div className="facilities">
                        <p>{BULLETIN} {t("house.price")} <span className="bold">{condoData.price} €</span></p>
                        <p>{BULLETIN} {t("house.house_square_metres")} <span className="bold">{condoData.condoSquareMetres} m2</span></p>
                        <p>{BULLETIN} {t("house.bathrooms")} <span className="bold">{condoData.bathrooms}</span></p>
                        <p>{BULLETIN} {t("house.rooms")} <span className="bold">{condoData.rooms}</span></p>
                        <p>{BULLETIN} {t("condo.floors")} <span className="bold">{condoData.floors}</span></p>
                    </div>
                    <Divider />
                    <p className="sub-title">{t("house.additional_content")}</p>
                    <div className="additional">
                        {condoData.additional.map((add: string) => (
                            <p key={Math.random()}>{BULLETIN} {add}</p>
                        ))}
                    </div>
                    <Divider />
                    <p className="sub-title">{t("house.contact")}</p>
                    <div className="contact">
                        <p>{BULLETIN} {t("house.id")} <span className="bold">{condoData.id}</span></p>
                        <p>{BULLETIN} {t("house.agent")} <span className="bold">{condoData.agent}</span></p>
                        <p>{BULLETIN} {t("house.mobile")} <span className="bold">{condoData.mobile}</span></p>
                        <p>{BULLETIN} E-Mail: <span className="bold">{condoData.email}</span></p>
                    </div>
                </section>
            )}
            <Footer />
        </>
    );
};
