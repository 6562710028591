import React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '../common/footer/footer';

export const RealEstateTypes: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<div id="real-estate-types">
				<p>{t("realEstateTypes.text")}</p>
				<div className="links">
					<a className="houses" href="/buy-house">
						<p className="location-name">{t("realEstateTypes.houses")}</p>
					</a>
					{/*<a className="condos" href="/condos-view">*/}
					{/*	<p className="location-name">{t("realEstateTypes.condos")}</p>*/}
					{/*</a>*/}
					<a className="plots" href="/buy-plot">
						<p className="location-name">{t("realEstateTypes.plots")}</p>
					</a>
				</div>
			</div>
			<Footer />
		</>
	);
};
