import React, { useEffect, useState } from 'react';
import { CondoDocument } from '../../common/typings';
import { ALL_LOCATIONS, ISTRIA, KVARNER } from '../../common/constants';
import { GetCondos, GetCondosByDestination } from '../../service/condos';
import { Footer } from '../common/footer/footer';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CondoCard } from '../common/condoCard/condo-card';

const { Option } = Select;

export const CondosView = () => {
	const { t } = useTranslation();
	const [destination, setDestination] = useState<string>('');
	const [condosData, setCondosData] = useState<CondoDocument[] | undefined>(undefined);

	useEffect(() => {
		function getCondos() {
			GetCondos().then(payload => setCondosData(payload));
		}

		function getCondosByDestination() {
			GetCondosByDestination(destination).then(payload => setCondosData(payload));
		}

		destination === ALL_LOCATIONS ? getCondos() : getCondosByDestination();
	}, [destination]);

	return (
		<>
			<section id="buy-house">
				<div className="search">
					<label>{t('destinations.choose_location')}</label>
					<Select className="select" defaultValue={destination} onChange={value => setDestination(value)}>
						<Option value={ALL_LOCATIONS}>{t("destinations.all_locations")}</Option>
						<Option value={ISTRIA}>{t("destinations.ISTRIA")}</Option>
						<Option value={KVARNER}>{t("destinations.KVARNER")}</Option>
					</Select>
				</div>
				{condosData && condosData.length !== 0 && (
					<>
						<h1>{resolveDestination(destination)}</h1>
						<div className="grid-3">
							{condosData.map((condo: CondoDocument) => (
								<CondoCard
									key={Math.random()}
									id={condo.id}
									cover={condo.cover}
									loading={false}
									condoSquareMetres={condo.condoSquareMetres}
									floors={condo.floors}
									rooms={condo.rooms}
									bathrooms={condo.bathrooms}
									price={condo.price}
									isBought={condo.isBought || false}
									isReserved={condo.isReserved || false}
									isNewListing={condo.isNewListing || false}
								/>
							))}
						</div>
					</>
				)}
			</section>
			<Footer />
		</>
	);

	function resolveDestination(destination: string) {
		if (destination === ALL_LOCATIONS)
			return t("destinations.all_locations")
		else if (destination === ISTRIA)
			return t("destinations.ISTRIA")
		else
			return t("destinations.KVARNER")
	}
};
