import React from 'react';
import { PlotCardProps } from '../../../common/typings';
import { Card, Button } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CardBanners } from '../cardBanners/card-banners';

export const PlotCard: React.FC<PlotCardProps> = (props: PlotCardProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id, cover, loading, location, squareMetres, price, shortDescription, pricePerSquare, isBought, isReserved, isNewListing } = props;

	const description = location + ' - ' + shortDescription;

	const routeChange = () => {
		let path = `/plot?id=` + id;

		history.push(path);
	};

	return (
		<div className="featured-card">
			<Card
				className="card"
				hoverable
				loading={loading}
				cover={
					<a href="javascript:void(0)" onClick={routeChange}><img alt="plot cover" src={cover} /></a>
				}
			>
				<Meta
					className="plots-description"
					title={id}
					description={description}
				/>
				<Button
					onClick={routeChange}
					className="btn"
					type="primary"
					block
				>
					{t('card.action')}
				</Button>
				<div className="info">
					<div className="rows">
						<p style={{ textTransform: 'lowercase' }} className="category"><span>{squareMetres}</span> m2</p>
						<p className="category center">|</p>
						<p style={{ textTransform: 'lowercase' }} className="category right"><span>{pricePerSquare}</span> €/m2</p>
					</div>
					<p className="text-center"><span>{price}</span>€</p>
				</div>
				<CardBanners
					isBought={isBought}
					isReserved={isReserved}
					isNewListing={isNewListing}
				/>
			</Card>
		</div>
	);
};
