import React from 'react';
import ImageGallery  from 'react-image-gallery';
import { Footer } from '../common/footer/footer';
import { useTranslation } from 'react-i18next';
import { GetPlotById } from '../../service/plots';
import { useGallery } from '../../service/common';
import { Loader } from '../common/loader/loader';

export const PlotView: React.FC = () => {
    const { t } = useTranslation();

    const { plotData, plotError } = GetPlotById();

    let { isLoading, gallery } = useGallery();

    if (plotError) {
        return (
            <h1>SORRY</h1>
        )
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <section id="gallery">
                <ImageGallery
                    showPlayButton={false}
                    items={gallery}
                    showFullscreenButton={true}
                    showBullets={true}
                />
            </section>
            {plotData && (
                <section id="plot">
                    <p className="title">{plotData.title}</p>
                    <p className="description">{plotData.description}</p>
                    <p>{t("house.price")} <span className="bold">{plotData.price}€</span></p>
                    <p>{t("house.square")} <span className="bold">{plotData.squareMetres} m2</span></p>
                    <p>{t("house.id")} <span className="bold">{plotData.id}</span></p>
                    <p>{t("house.agent")} <span className="bold">{plotData.agent}</span></p>
                    <p>{t("house.mobile")} <span className="bold">{plotData.mobile}</span></p>
                    <p>E-Mail: <span className="bold">{plotData.email}</span></p>
                </section>
            )}
            <Footer />
        </>
    );
};
