import React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '../common/footer/footer';

export const RentalLocations: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<div id="holiday-locations">
				<p>{t("holidayLocations.first")}<br/><br/>{t("holidayLocations.second")}</p>
				<div className="links">
					<a className="istria" href="/rentals-view?location=ISTRIA">
						<p className="location-name">{t("holidayLocations.istria")}</p>
					</a>
					<a className="kvarner" href="/rentals-view?location=KVARNER">
						<p className="location-name">{t("holidayLocations.kvarner")}</p>
					</a>
					<a className="continental" href="/rentals-view?location=CONTINENTAL">
						<p className="location-name">{t("holidayLocations.continental")}</p>
					</a>
				</div>
			</div>
			<Footer />
		</>
	);
};
