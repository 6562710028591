import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { hr_HR } from "./common/constants";

const getLanguage = () => localStorage.getItem("i18nextLng");

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: getLanguage() || hr_HR,
    debug: true,
    detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie']
    },
    interpolation: {
        escapeValue: false
    }
}).then()