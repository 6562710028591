import React, { useEffect, useState } from 'react';
import { LoaderProps } from '../../../common/typings';

import logo from '../../../images/lux_adria-logo.svg';

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	const { isLoading } = props;
	const [fakeIsLoading, setFakeIsLoading] = useState(true)

	useEffect(() => {
		if (isLoading) {
			setTimeout(() => {
				setFakeIsLoading(false)
			}, 1000)
		}

	}, [isLoading])

	if (fakeIsLoading) {
		return (
			<div id="loader">
				<img src={logo} alt="logo" />
			</div>
		)
	} else {
		return null
	}
};
