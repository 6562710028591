import React from 'react';
import { RentalDocument } from '../../common/typings';
import { RentalCard } from '../common/rentalCard/rentalCard';
import { useLocation } from 'react-router-dom';
import { GetRentalsByDestination } from '../../service/rentals';
import { useTranslation } from 'react-i18next';

export const RentalsView: React.FC = () => {
	const { t } = useTranslation();
	const location = UseQuery().get("location") || '';

	const { rentalData, rentalLoading } = GetRentalsByDestination(location);

	return (
		<section id="book-holiday">
			{rentalData && rentalData.length !== 0 && (
				<>
					<h1>{t("destinations." + location)}</h1>
					<div className="grid-3">
						{rentalData.map((rental: RentalDocument) => (
							<RentalCard
								key={Math.random()}
								featuredDetails={rental.featuredDetails}
								id={rental.id}
								location={rental.location}
								cover={rental.cover}
								description={rental.shortDescription || ""}
								loading={rentalLoading}
								rate={rental.rate}
							/>
						))}
					</div>
				</>
			)}
		</section>
	);

	function UseQuery() {
		return new URLSearchParams(useLocation().search);
	}
};
