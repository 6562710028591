import { UseQuery } from '../common/utils';
import { useEffect, useState } from 'react';
import { Gallery } from '../common/typings';
import { storage } from './firebase';

export const useGallery = () => {
	const id = UseQuery().get('id') || '';
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [gallery, setGallery] = useState<Gallery[]>([]);
	const storageRef = storage.ref(id);

	useEffect(() => {
		setIsLoading(true);

		storageRef.listAll().then(function (res) {
			res.items.forEach((imageRef) => {
				imageRef.getDownloadURL().then((url) => {
					setGallery(oldArray => [...oldArray, { original: url, thumbnail: url }]);
				});
			});

			setIsLoading(false);
		}).catch(function (error) {
			setIsLoading(false);
			console.log(error);
		});
	}, []);

	return { isLoading, gallery };
};
