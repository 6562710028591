import React from 'react';
import {
	InstagramOutlined,
	FacebookOutlined,
	YoutubeOutlined,
	TwitterOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const Footer: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div id="footer">
			<div className="grid-3">
				<div className="info">
					<h2>{t("footer.info")}</h2>
					<ul>
						<li>Lux Adria,</li>
						<li>{t("footer.company-info")}</li>
						<li>{t("footer.founders")}</li>
						<li>{t("footer.id")}</li>
						<li>Zagrebačka banka (Unicredite grupa)</li>
						<li>{t("footer.bank-number")}</li>
					</ul>
				</div>
				<div className="info">
					<h2>{t("footer.contact")}</h2>
					<ul>
						<li>E-Mail: <a href="mailto:info@luxadria.com">info@luxadria.com</a></li>
						<li>{t("footer.jozo-mobile")}</li>
						<li>{t("footer.matej-mobile")}</li>
					</ul>
				</div>
				<div className="info social-icons">
					<InstagramOutlined className="contact-icon" />
					<FacebookOutlined className="contact-icon" />
					<YoutubeOutlined className="contact-icon" />
					<TwitterOutlined className="contact-icon" />
				</div>
			</div>
		</div>
	);
};