import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

firebase.initializeApp({
	apiKey: "AIzaSyA5FPRt2LdmHpWY1tSRrwB23favbebqcko",
	authDomain: "luxadria-95a6b.firebaseapp.com",
	projectId: "luxadria-95a6b",
	storageBucket: "luxadria-95a6b.appspot.com",
	messagingSenderId: "904864233247",
	appId: "1:904864233247:web:27f43d108bbb72857d222a",
	measurementId: "G-R3S1LYDDQ9"
});

export const firestore = firebase.firestore();

export const storage = firebase.storage();